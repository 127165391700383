export interface Document {
  name: "Archers pré-inscrits" | "Archers sélectionnés" | "Candidature" | "Mandat" | "Répartition" | "Pré-inscription" | "Résultats" | "Paiement"
  link: string
  date: string
  type: "PDF" | "Formulaire en ligne"
  important: boolean
}

export interface Quota {
  name: string
  shortname: string
  value: number
  registered: number
}

export interface Championnat {
  name: string
  shortname: string
  season: string
  place: string
  dateStart: string
  dateEnd: string | null
  documents: Document[]
  quotas: Quota[] | null
  updateDate: string
  default: boolean
}

const championnats: Championnat[] = [
  // {
  //   name: "Salle Jeunes 2024",
  //   shortname: "salle-jeunes",
  //   season: "2024",
  //   place: "Mulhouse",
  //   dateStart: "2024-02-03",
  //   dateEnd: "2024-02-03",
  //   documents: [
  //     {
  //       name: "Mandat",
  //       link: "/vie-sportive/mandats/64681.pdf",
  //       date: "2023-12-20",
  //       type: "PDF",
  //       important: true,
  //     },
  //     {
  //       name: "Pré-inscription",
  //       link: "https://forms.gle/8bi3HgWYxuN7bbM7A",
  //       date: "2022-12-15",
  //       type: "Formulaire en ligne",
  //       important: true,
  //     },
  //     {
  //       name: "Archers sélectionnés",
  //       link: "https://cloud.tiralarc-grand-est.fr/s/NCHpyy2XGsZz8fG",
  //       date: "",
  //       type: "PDF",
  //       important: true,
  //     },
  //     {
  //       name: "Paiement",
  //       link: "https://www.helloasso.com/associations/comite-regional-de-tir-a-l-arc-du-grand-est/evenements/inscription-cr-salle-2024-a-mulhouse",
  //       date: "2024-01-22",
  //       type: "Formulaire en ligne",
  //       important: true,
  //     },
  //   ],
  //   quotas: [
  //     {
  //       name: "U11 Femme Arc classique",
  //       shortname: "U11FCL",
  //       value: 4,
  //       registered: 3,
  //     },
  //     {
  //       name: "U11 Homme Arc classique",
  //       shortname: "U11HCL",
  //       value: 4,
  //       registered: 8,
  //     },
  //     {
  //       name: "U13 Femme Arc classique",
  //       shortname: "U13FCL",
  //       value: 8,
  //       registered: 7,
  //     },
  //     {
  //       name: "U13 Homme Arc classique",
  //       shortname: "U13HCL",
  //       value: 8,
  //       registered: 12,
  //     },
  //     {
  //       name: "U15 Femme Arc classique",
  //       shortname: "U15FCL",
  //       value: 16,
  //       registered: 14,
  //     },
  //     {
  //       name: "U15 Homme Arc classique",
  //       shortname: "U15HCL",
  //       value: 16,
  //       registered: 14,
  //     },
  //     {
  //       name: "U18 Femme Arc classique",
  //       shortname: "U18FCL",
  //       value: 16,
  //       registered: 20,
  //     },
  //     {
  //       name: "U18 Homme Arc classique",
  //       shortname: "U18HCL",
  //       value: 16,
  //       registered: 21,
  //     },
  //     {
  //       name: "U21 Femme Arc classique",
  //       shortname: "U21FCL",
  //       value: 8,
  //       registered: 9,
  //     },
  //     {
  //       name: "U21 Homme Arc classique",
  //       shortname: "U21HCL",
  //       value: 8,
  //       registered: 14,
  //     },
  //     {
  //       name: "U18 Femme Arc à poulies",
  //       shortname: "U18FCO",
  //       value: 4,
  //       registered: 2,
  //     },
  //     {
  //       name: "U18 Homme Arc à poulies",
  //       shortname: "U18HCO",
  //       value: 4,
  //       registered: 3,
  //     },
  //     {
  //       name: "U21 Femme Arc à poulies",
  //       shortname: "U21FCO",
  //       value: 4,
  //       registered: 1,
  //     },
  //     {
  //       name: "U21 Homme Arc à poulies",
  //       shortname: "U21HCO",
  //       value: 4,
  //       registered: 2,
  //     },
  //     {
  //       name: "U18 Femme Arc nu",
  //       shortname: "U18FBB",
  //       value: 4,
  //       registered: 5,
  //     },
  //     {
  //       name: "U18 Homme Arc nu",
  //       shortname: "U18HBB",
  //       value: 4,
  //       registered: 6,
  //     },
  //   ],
  //   updateDate: "2024-02-01",
  //   default: false,
  // },
  // {
  //   name: "Salle Adultes 2024",
  //   shortname: "salle-adultes",
  //   season: "2024",
  //   place: "Mulhouse",
  //   dateStart: "2024-02-04",
  //   dateEnd: "2024-02-04",
  //   documents: [
  //     {
  //       name: "Mandat",
  //       link: "/vie-sportive/mandats/64682.pdf",
  //       date: "2023-12-20",
  //       type: "PDF",
  //       important: true,
  //     },
  //     {
  //       name: "Pré-inscription",
  //       link: "https://forms.gle/yfLfrZQXWzQQax287",
  //       date: "2023-12-15",
  //       type: "Formulaire en ligne",
  //       important: true,
  //     },
  //     {
  //       name: "Archers sélectionnés",
  //       link: "https://cloud.tiralarc-grand-est.fr/s/NCHpyy2XGsZz8fG",
  //       date: "",
  //       type: "PDF",
  //       important: true,
  //     },
  //     {
  //       name: "Paiement",
  //       link: "https://www.helloasso.com/associations/comite-regional-de-tir-a-l-arc-du-grand-est/evenements/inscription-cr-salle-2024-a-mulhouse",
  //       date: "2024-01-22",
  //       type: "Formulaire en ligne",
  //       important: true,
  //     },
  //   ],
  //   quotas: [
  //     {
  //       name: "Sénior 1 Femme Arc classique",
  //       shortname: "S1FCL",
  //       value: 8,
  //       registered: 16,
  //     },
  //     {
  //       name: "Sénior 1 Homme Arc classique",
  //       shortname: "S1HCL",
  //       value: 16,
  //       registered: 20,
  //     },
  //     {
  //       name: "Sénior 2 Femme Arc classique",
  //       shortname: "S2FCL",
  //       value: 8,
  //       registered: 16,
  //     },
  //     {
  //       name: "Sénior 2 Homme Arc classique",
  //       shortname: "S2HCL",
  //       value: 16,
  //       registered: 24,
  //     },
  //     {
  //       name: "Sénior 3 Femme Arc classique",
  //       shortname: "S3FCL",
  //       value: 4,
  //       registered: 3,
  //     },
  //     {
  //       name: "Sénior 3 Homme Arc classique",
  //       shortname: "S3HCL",
  //       value: 8,
  //       registered: 9,
  //     },
  //     {
  //       name: "Sénior 1 Femme Arc à poulies",
  //       shortname: "S1FCO",
  //       value: 4,
  //       registered: 4,
  //     },
  //     {
  //       name: "Sénior 1 Homme Arc à poulies",
  //       shortname: "S1HCO",
  //       value: 8,
  //       registered: 8,
  //     },
  //     {
  //       name: "Sénior 2 Femme Arc à poulies",
  //       shortname: "S2FCO",
  //       value: 4,
  //       registered: 6,
  //     },
  //     {
  //       name: "Sénior 2 Homme Arc à poulies",
  //       shortname: "S2HCO",
  //       value: 16,
  //       registered: 17,
  //     },
  //     {
  //       name: "Sénior 3 Femme Arc à poulies",
  //       shortname: "S3FCO",
  //       value: 4,
  //       registered: 4,
  //     },
  //     {
  //       name: "Sénior 3 Homme Arc à poulies",
  //       shortname: "S3HCO",
  //       value: 8,
  //       registered: 9,
  //     },
  //     {
  //       name: "Scratch Femme Arc nu",
  //       shortname: "SFBB",
  //       value: 8,
  //       registered: 10,
  //     },
  //     {
  //       name: "Scratch Homme Arc nu",
  //       shortname: "SHBB",
  //       value: 16,
  //       registered: 16,
  //     },
  //   ],
  //   updateDate: "2024-02-01",
  //   default: true,
  // },
  {
    name: "Beursault 2024",
    shortname: "beursault",
    season: "2024",
    place: "Brienne - Châlons - Éclaron - Einville - Fismes - Mont l'Étroit",
    dateStart: "2024-04-14",
    dateEnd: "2024-09-08",
    documents: [
      {
        name: "Résultats",
        link: "https://cloud.tiralarc-grand-est.fr/s/BdqHfnpkW5DzqRa",
        date: "2024-09-09",
        type: "PDF",
        important: true,
      },
    ],
    quotas: null,
    updateDate: "2024-09-09",
    default: true,
  },
  {
    name: " - Demande d'organisation -",
    shortname: "demande-orga",
    season: "2025",
    place: "",
    dateStart: "2024-09-01",
    dateEnd: "2025-08-31",
    documents: [
      {
        name: "Candidature",
        link: "https://forms.gle/AKb3dU3W2wXCF8eV6",
        date: "2023-12-16",
        type: "Formulaire en ligne",
        important: true,
      },
    ],
    quotas: null,
    updateDate: "2023-12-16",
    default: false,
  },
]

export default championnats
